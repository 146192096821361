<template>
  <modal
    name="add-skills"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t("cv.profile.slills.components.add.add") }}</h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen xl:pb-5 pb-20"
      novalidate
    >
      <div class="space-y-4 pb-3">
        <multiSelectVue
          :maxLength="80"
          :placeholder="
            $t('cv.profile.slills.components.edit.slillsPlacholder')
          "
          :label="$t('cv.profile.slills.components.edit.slills')"
          ref="SkillRef"
          v-model="name"
          :required="true"
          :getOptions="getSkills"
          :taggable="false"
        />

        <customInput
          v-model="degree"
          type="select"
          :selectList="skilsDegree"
          :placeholder="$t('cv.profile.slills.components.add.levelPlacholder')"
          :title="$t('cv.profile.slills.components.add.level')"
        />

        <requiredField />
        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :disabled="!name"
              :load="load"
              :name="$t('cv.profile.slills.components.add.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { skills, utility } from "@/networking/urlmanager.js";

export default {
  name: "skills-add",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      name: null,
      degree: 0,
      skilsDegree: [
        {
          value: 0,
          name: this.$t("cv.profile.slills.index.unspecified"),
        },
        {
          value: 1,
          name: this.$t("cv.profile.slills.index.beginner"),
        },
        {
          value: 2,
          name: this.$t("cv.profile.slills.index.intermediateLevel"),
        },
        {
          value: 3,
          name: this.$t("cv.profile.slills.index.advancedLevel"),
        },
      ],
    };
  },
  methods: {
    show() {
      this.name = null;
      this.degree = 0;
      this.$modal.show("add-skills");
    },
    hide() {
      this.$modal.hide("add-skills");
    },
    save() {
      this.load = true;

      this.$refs.SkillRef.validate();

      this.$store.commit("addSkils", {
        name: this.name,
        level: this.degree,
      });

      this.name = "";
      this.degree = 0;
      this.successMessage();

      this.load = false;
      this.hide();
    },
    add() {
      this.load = true;
      this.$refs.SkillRef.validate();

      let list = [
        {
          ...this.name,
          level: this.degree,
        },
      ];
      this.axios
        .post(skills.add, {
          cvId: this.$route.params.id,
          list: JSON.stringify(list),
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.$emit("refresh", true);
          this.load = false;
          this.hide();
        })
        .catch((err) => {
          this.authController(err);
          this.load = false;
        })
        .finally(() => {
          this.load = false;
        });
    },
    async getSkills() {
      const response = await this.axios.get(utility.getSkillAll, {
        params: {
          type: 1,
        },
      });
      return response.data.data;
    },
  },
};
</script>
