<template>
  <modal
    name="edit-computer-skills"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.information.computerSkills.edit.edit") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? edit() : save()"
      class="p-5 max-h-screen xl:pb-5 pb-20"
      novalidate
    >
      <div class="space-y-4 pb-3">
        <multiSelectVue
          :maxLength="100"
          :placeholder="
            $t('cv.profile.information.computerSkills.add.SkillPlacholder')
          "
          :label="$t('cv.profile.information.computerSkills.add.SkillTitle')"
          ref="SkillRef"
          v-model="name"
          :required="true"
          :getOptions="getSkills"
        />

        <customInput
          v-model="degree"
          type="select"
          :selectList="skilsDegree"
          :placeholder="
            $t('cv.profile.information.computerSkills.edit.levelPlacholder')
          "
          :title="$t('cv.profile.information.computerSkills.edit.level')"
        />

        <requiredField />
        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem

              :disabled="!name"
              :load="load"
              :name="$t('cv.profile.information.computerSkills.add.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { computerSkills, utility } from "@/networking/urlmanager.js";

export default {
  name: "edit-language",
  props: ["isEdit", "Detail", "index"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      rowId: "",
      name: "",
      degree: 0,

      skilsDegree: [
        {
          value: 0,
          name: this.$t("cv.profile.slills.index.unspecified"),
        },
        {
          value: 1,
          name: this.$t("cv.profile.slills.index.beginner"),
        },
        {
          value: 2,
          name: this.$t("cv.profile.slills.index.intermediateLevel"),
        },
        {
          value: 3,
          name: this.$t("cv.profile.slills.index.advancedLevel"),
        },
      ],
    };
  },
  methods: {
    show() {
      setTimeout(async () => {
        this.degree = this.Detail.level;
        this.rowId = this.Detail.id;
        this.name = this.Detail.name;
      }, 100);
      this.$modal.show("edit-computer-skills");
    },
    hide() {
      this.$modal.hide("edit-computer-skills");
    },
    save() {
      this.load = true;

      this.$refs.SkillRef.validate();

      this.$emit("loadChance", true);
      this.$store.commit("editComputerSkils", {
        index: this.index,
        list: {
          name: this.name,
          level: this.degree,
        },
      });

      this.language = "";
      this.degree = 0;
      this.successMessage();

      this.load = false;
      this.$emit("loadChance", true);
      this.hide();
    },
    edit() {
      this.load = true;

      this.$refs.SkillRef.validate();

      this.axios
        .post(computerSkills.edit, {
          rowId: this.rowId,
          cvId: this.$route.params.id,
          list: [
            {
              ...this.name,
              level: this.degree,
            },
          ],
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.$emit("refresh", true);
          this.load = false;
          this.hide();
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
          this.load = false;
        });
    },
    async getSkills() {
      const response = await this.axios.get(utility.getSkillAll, {
        params: {
          type: 2,
        },
      });
      return response.data.data;
    },
  },
};
</script>
