<template>
    <div class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 relative max-sm:my-8">

        <personelInformationModal ref="personelInformationModal" :Detail="selected" />
        <div class="tableOverflow scroltypey mt-5" data-v-step="1">
            <div class="tableWidth">
                <div class="tableContainer">
                    <div class="tableHead">
                        <ul class="tableUl">

                            <li class="w-3/12">{{ $t("hired.index.table.name") }}</li>

                            <li class="w-2/12 max-md:w-2/12">
                                {{ $t("hired.index.table.position") }}
                            </li>
                            <li class="w-2/12 max-md:w-2/12">
                                {{ $t("hired.index.table.hiredDate") }}
                            </li>
                            <li class="w-2/12 max-md:w-2/12">
                                {{ $t("hired.index.table.firedDate") }}
                            </li>
                            <li class="w-3/12 max-md:w-3/12 flex justify-end">
                                <div class="w-[300px] tableSearch relative">
                                    <input v-model="search" @keypress.enter="getSearch()" type="text"
                                        class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                                        :placeholder="$t('hired.index.table.searchEmployee')" />
                                    <button class="px-3 textcol absolute right-0">
                                        <i @click="getSearch()" class="fa-solid fa-magnifying-glass"></i>
                                    </button>


                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="tableBody scroltype">
                        <div class="tableBodyContainer">
                            <ul v-for="(item, index) in list" :key="index" class="tableBodyUl">


                                <li @click="$router.push(`/cv/profile/${item.id}`)"
                                    class="cursor-pointer select-none w-3/12 py-2.5 flex items-center gap-2" id="">
                                    <div v-if="isWithinTwoDays(item.hiredDate)"
                                        class="bg-blue-100 text-blue-500 px-1 rounded-md text-xs">
                                        {{ $t("new") }}
                                    </div>
                                    {{ formatText(item.fullname) }}
                                </li>

                                <li @click="$router.push(`/cv/profile/${item.id}`)"
                                    class="cursor-pointer select-none w-2/12 max-md:w-2/12 py-2.5  pr-5">
                                    {{
                                        item.position
                                            ? formatText(item.position)
                                            : $t("hired.index.unspecified")
                                    }}
                                </li>

                                <li class="w-2/12 max-md:w-2/12 flex gap-6 justify-between px-3 items-center">

                                    <p class="w-full">{{ getDate(item.hiredDate) }}</p>
                                </li>
                                <li class="w-2/12 max-md:w-2/12 flex gap-6 justify-between px-3 items-center">

                                    <p class="w-full">{{ getDate(item.dismissedDate) }}</p>
                                </li>
                                <li class="w-3/12 max-md:w-3/12 flex gap-6 justify-end px-3 items-center">



                                    <div class="flex gap-4 w-[100px] items-center">
                                        <asyncButton type="button" @click="showpersonelInformation(item)" :hiddenText="true"
                                        v-if="item.shortListStatus"
                                           :buttonClass="'w-full !px-0 !gap-0 '
                                                " :awesome="'fa-solid fa-magnifying-glass text-xs font-semibold'
                                                    "
                                            containerBackground="!text-black md:!w-auto justify-center !w-full !bg-transparent"
                                            imgClass="w-5" container-class="w-full md:w-auto" :isTooltip="true"
                                            :toolTipTitle="$t('project.tableInterview.tooltip.profile')
                                                " :tooltipTop="true"
                                            toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                                            spinnerClass="!border-t-transparent !border-black !text-black"
                                            tooltipRotateClass="ml-4" />
                                        <asyncButton type="button" @click="deleteItem(item)" :hiddenText="true"
                                        v-if="item.shortListStatus != 8"
                                           :buttonClass="'w-full !px-0 !gap-0 '
                                                " :awesome="'fa-solid fa-xmark text-base text-red-600'
                                                    "
                                            containerBackground="!text-black md:!w-auto justify-center !w-full !bg-transparent"
                                            imgClass="w-5" container-class="w-full md:w-auto" :isTooltip="true"
                                            :toolTipTitle="$t('project.tableInterview.tooltip.reject')
                                                " :tooltipTop="true"
                                            toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                                            spinnerClass="!border-t-transparent !border-black !text-black"
                                            tooltipRotateClass="ml-4" />
                                    </div>
                                </li>
                            </ul>
                            <tableLoader :load="load" :length="list.length" colspan="7" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <pagination v-model="currentPage" :pageCount="pageCount" :totalRowCount="totalCount" />
    </div>
</template>

<script>
import tableLoader from "@/components/table-loader.vue";
import pagination from "@/components/pagination.vue";
import personelInformationModal from "../project/projectUser/components/shortlist/components/personelInformation/index.vue";

import moment from "moment";
import "moment/locale/tr";
import { hired, shortList } from "@/networking/urlmanager";
import asyncButton from "@/components/button.vue";

export default {
    name: "hired-page",
    components: {
        tableLoader,
        pagination,
        asyncButton,
        personelInformationModal
    },
    data() {
        return {
            list: [],
            checkedList: [],
            search: this.$route.query.search || "",
            currentPage: this.$route.query.currentPage || 0,
            totalCount: "",
            pageCount: 1,
            rowId: "",
            load: false,
            selected: "",
        };
    },
    methods: {
        getAll() {
            this.load = true;
            this.list = [];
            this.axios
                .get(hired.getAll, {
                    params: {
                        page: this.currentPage,
                        search: this.search,
                    },
                })
                .then((response) => {
                    this.list = response.data.data.detail;
                    this.totalCount = response.data.data.totalCount;
                    this.pageCount = response.data.data.pageCount;
                    this.load = false;
                })
                .catch((error) => {
                    this.list = [];
                    this.totalCount = 0;
                    this.pageCount = 1;
                    this.load = false;
                });
        },

        selectAll(val) {
            if (val.target.checked) {
                for (let index = 0; index < this.list.length; index++) {
                    this.checkedList.push(index);
                }
            } else {
                this.checkedList = [];
            }
        },

        getSearch() {
            this.$router.push({
                query: {
                    page: 0,
                    search: this.search,
                },
            });
        },
        isWithinTwoDays(dateString) {
            const now = moment();
            const date = moment(dateString);
            const diffDays = now.diff(date, "days");
            return diffDays <= 2;
        },

        deleteItem(item) {
            this.$swal({
                text: this.$t("project.tableInterview.verifiedMessage.cancelReason"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: this.$t(
                    "project.tableInterview.verifiedMessage.cancelReasonConfirm"
                ),
                cancelButtonText: this.$t(
                    "project.tableInterview.verifiedMessage.cancelReasonDecline"
                ),
                input: "text",
                inputAttributes: {
                    autocapitalize: "off",
                },
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.deleteFire(item, result)
                }
            });
        },
        async deleteFire(item, result) {
            this.list = [];

            this.load = true;

            await this.axios
                .post(shortList.delete, {
                    rowId: item.shortListRowId,
                    projectId: item.projectId,
                    cvId: item.cvId,
                    message: result.value,
                    fullname: item.fullname,
                })
                .then((res) => {
                    this.successMessage(res.data.message);
                    this.getAll();
                })
                .catch((err) => {
                    const errorMessage = err.response
                        ? err.response.data.message
                        : err.message;
                    this.authController(errorMessage);
                }).finally(() => {
                    this.load = false;
                });
        },
        getDate(val) {
            if (val) return moment(val).format("LL");
            else return '-';
        },
        showpersonelInformation(val) {
            this.selected = val;
            this.$refs.personelInformationModal.show();
        },

    },

    // mounted() {
    //   this.getAll();
    // },
    created() {

        this.getAll();

    },

    watch: {
        currentPage(val) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page: val,
                },
            });

        },
        search(val) {
            if (!val) {
                this.$router.push({
                    query: {
                        page: 0,
                        search: undefined,
                    },
                });
            }
        },

        $route(to, from) {
            this.currentPage = to.query.page || 0;
            this.getAll();
        },
    },
};
</script>