<template>
  <div class="w-full">
    <multiselect
      :internal-search="false"
      @search-change="asyncFind"
      :options="filterList"
      :value="getList()"
      @select="onSelect"
      @remove="onRemove"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :loading="load"
      placeholder=""
      label="name"
      track-by="id"
      :selectLabel="$t('globalComponents.dev.institutionSelect.add')"
      :deselectLabel="$t('globalComponents.dev.institutionSelect.delete')"
      :preselect-first="true"
      :disabled="disabled"
    >
      <template v-slot:selection="{ values }">
        {{ values.length }}
        {{ $t("globalComponents.dev.institutionSelect.institutionChosen") }}
      </template>
    </multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import { utility } from "@/networking/urlmanager";
import Fuse from "fuse.js";

export default {
  props: ["value", "isEdit", "educationType", "disabled"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: [],
      List: [],
      filterList: [],
      typeList: [],
      load: false,
      fuse: null,
    };
  },
  methods: {
    onSelect(val) {
      const findIndex = this.selectedList.findIndex(
        (item) => item.id == val.id
      );
      if (findIndex > -1) {
        const item = this.selectedList[findIndex];
        if (this.isEdit && item.status == 3 && item.rowId)
          this.selectedList[findIndex].status = 1;
        if (item.status != 1) this.selectedList[findIndex].status = 2;
      } else {
        const tag = {
          id: val.id,
          name: val.name,
          status: 2,
        };
        this.selectedList.push(tag);
      }
    },
    onRemove(val) {
      const findIndex = this.selectedList.findIndex((r) => r.id == val.id);
      if (this.isEdit && this.selectedList[findIndex].rowId)
        this.selectedList[findIndex].status = 3;
      else this.selectedList.splice(findIndex, 1);
    },
    getList() {
      return this.selectedList.filter((r) => r.status != 3);
    },
    asyncFind(val) {
      if (!this.fuse) {
        this.filterList = [...this.typeList];
        return;
      }

      if (!val) {
        this.filterList = [...this.typeList];
        return;
      }

      const results = this.fuse.search(val);
      const newFilterList = results.map((result) => result.item)
        .filter(item => this.typeList.some(t => t.id === item.id));
      this.filterList = newFilterList;

      this.sortFilterList();
    },
    sortFilterList() {
      this.filterList.sort((a, b) => {
        const indexA = this.selectedList.findIndex((r) => r.id === a.id);
        const indexB = this.selectedList.findIndex((r) => r.id === b.id);
        return indexB - indexA;
      });
    },
    async getAll() {
      try {
        this.load = true;
        const response = await this.axios.get(utility.getInstitutionAll);
        this.List = response.data.data;
        this.typeList = response.data.data;
        this.filterList = [...this.List];
        
        this.fuse = new Fuse(this.List, {
          keys: ["name"],
          includeScore: false,
          threshold: 0.4,
        });

        if (this.educationType) {
          this.filterByEducationType(this.educationType);
        }

        this.load = false;
      } catch (error) {
        this.load = false;
        console.error("Okul bilgileri yüklenirken bir hata oluştu:", error);
      }
    },
    filterByEducationType(types) {
      this.typeList = this.List.filter((item) => {
        return types.length > 0
          ? types.some((r) => (r.id == 1 ? item.level == 1 : item.level == 2))
          : true;
      });
      this.filterList = [...this.typeList];
      this.sortFilterList();
    }
  },
  async created() {
    await this.getAll();
    this.selectedList = this.value || [];
  },
  watch: {
    selectedList(val) {
      this.sortFilterList();
      this.$emit("change", val);
    },
    value(val) {
      this.selectedList = val || [];
    },
    educationType: {
      handler(val) {
        if (val) {
          this.filterByEducationType(val);
        }
      },
      immediate: true
    }
  },
};
</script>