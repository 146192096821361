<template>
    <div>
        <v-tour name="myTour" :steps="steps()" :options="{
            useKeyboardNavigation: false,
            labels: {
                buttonSkip: $t('globalComponents.tour.buttonSkip'),
                buttonPrevious: $t('globalComponents.tour.buttonPrevious'),
                buttonNext: $t('globalComponents.tour.buttonNext'),
                buttonStop: $t('globalComponents.tour.buttonStop')

            }
        }" :callbacks="myCallbacks"></v-tour>
    </div>
</template>

<script>
import { users } from "@/networking/urlmanager";
export default {
    name: 'my-tour',
    props: ['steps', "moduleId"],
    data() {
        return {
            myCallbacks: {
                onFinish: this.save,
                onStop: this.save
            },
        }
    },
    mounted() {
        this.$tours['myTour'].start();
    },
    methods: {
        save() {
            if (this.moduleId) {
                this.axios.post(users.tourControl, {
                    moduleId: this.moduleId,
                    email: this.$store.state.userData.email
                }).then(() => {
                    this.$store.commit("setTourModule", this.moduleId);
                }).catch((error) => {
                    console.error(error);
                });
            }

        },
    },

    watch: {
        '$store.state.userData.locale': {
            handler() {
                console.log("locale changed",this.$tours);
               this.$tours['myTour'].steps = this.steps;
                this.$tours['myTour'].start();

            },
        }
    }
}
</script>