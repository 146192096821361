<template>
  <div
    class="z-[996] relative bgcol min-h-screen border-blue-600 bg-custom-dark text-white border-r-2 border-opacity-5 py-5 max-lg:fixed max-lg:mt-24"
    :class="
      dataSidebar == true
        ? 'w-[22%] maxw max-xl:w-[22%] max-lg:w-[0%] max-md:w-[0%] transition-all ease-in-out'
        : 'w-[5%] max-xl:w-[7%] max-lg:w-[27%] max-md:w-full transition-all ease-in-out'
    "
  >
    <h1 class="mx-auto px-3">
      <img
        v-if="!dataSidebar"
        src="@/assets/global/logo.svg"
        alt=""
        class="mx-auto mt-3"
      />
      <h1 class="mx-auto px-3">
        <img
          v-if="dataSidebar"
          src="@/assets/global/cvusLogo.png"
          alt=""
          class="mx-auto mt-3"
        />
      </h1>
    </h1>

    <!-- LİNK -->
    <div class="mt-10 max-lg:mt-8">
      <div v-for="item in List" :key="item.id">
        <router-link
          class="w-full"
          :to="item.disabled ? '' : item.to"
          :class="dataSidebar == true ? 'max-lg:hidden' : 'max-lg:block '"
          v-if="item.authority"
        >
          <div
            class="flex w-full items-center mt-2 border-l-2 text-gray-300 border-[#182451] px-5 max-lg:gap-5 hover:bg-white hover:bg-opacity-5 hover:!border-gray-500 hover:!text-white"
            :class="
              item.routerName.includes($route.name)
                ? active
                : item.disabled
                ? 'opacity-50'
                : ''
            "
            @click="dataSidebar = isMobile ? !dataSidebar : dataSidebar == true"
          >
            <div
              class="flex items-center justify-center max-lg:justify-start"
              :class="
                dataSidebar == false ? 'lg:w-full mr-0 py-2 my-2 ' : 'mr-4'
              "
            >
              <img
                :src="item.icon"
                class="object-contain text-gray-300"
                :class="{
                  'h-5 w-5': dataSidebar == false,
                  'h-6 w-6': dataSidebar,
                  'opacity-100': $route.name == item.routerName,
                  'opacity-60': $route.name != item.routerName,
                  'opacity-20': item.disabled,
                }"
              />
            </div>
            <button
              class="text-left flex justify-between py-3.5 w-full items-center font-semibold text-[0.9rem] font-Arial"
              :class="{ 'hidden max-lg:inline': dataSidebar == false }"
            >
              {{ item.name }}

              <span
                class="text-red-600 opacity-100 text-sm"
                v-if="item.disabled"
              >
                {{ $t("globalComponents.sideBar.soon") }}
              </span>
            </button>
          </div>
        </router-link>
      </div>
    </div>
    <!-- LİNK END-->

    <div class="mt-16 fixed bottom-5" :class="widthSide">
      <div
        class="w-full"
        :class="dataSidebar == true ? 'max-lg:hidden' : 'max-lg:hidden'"
      >
        <div
          class="flex w-full items-center mt-2 border-l-2 text-gray-300 border-[#182451] px-5"
        >
          <button
            @click="clearAll()"
            class="w-6 h-6 flex items-center justify-center mr-4"
          >
            <img src="@/assets/sidebar/signOut.svg" alt="" />
          </button>
          <button
            @click="clearAll()"
            class="text-left py-4 w-auto items-center font-semibold text-base"
            :class="dataSidebar == false && 'hidden max-lg:block'"
          >
            {{ $t("globalComponents.sideBar.signOut") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "side-bar",
  props: ["isSidebar"],
  computed: {
    List() {
      return [
        {
          to: "/project/0",
          name: this.$t("globalComponents.sideBar.openPositions"),
          routerName: ["project", "projectUser"],
          icon: require("@/assets/sidebar/layers.png"),
          disabled: false,
          authority: true,
        },
        {
          to: "/cv/0",
          name: this.$t("globalComponents.sideBar.resumes"),
          routerName: ["cv", "cvProfile", "cvSaveMiddleware"],
          icon: require("@/assets/sidebar/paper.png"),
          disabled: false,
          authority: true,
        },
        {
          to: "/general",
          name: this.$t("globalComponents.sideBar.generalReporting"),
          routerName: "general",
          icon: require("@/assets/sidebar/dashboard.png"),
          disabled: false,
          authority: true,
        },
        {
          to: "/hired",
          name: this.$t("globalComponents.sideBar.hired"),
          routerName: "hired",
          icon: require("@/assets/sidebar/approved.png"),
          disabled: false,
          authority: true,
        },
        {
          to: "/users",
          name: this.$t("globalComponents.sideBar.users"),
          routerName: "users",
          icon: require("@/assets/sidebar/users.png"),
          disabled: false,
          authority:
            this.$store.state.userData.authority == 1 ||
            this.$store.state.userData.authority == 3,
        },
        // {
        //   to: "/support",
        //   name: this.$t("globalComponents.sideBar.support"),
        //   routerName: ["support", "supportDetail"],
        //   icon: require("@/assets/sidebar/support.png"),
        //   disabled: false,
        //   authority: true,
        // },
        {
          to: "/setting",
          name: this.$t("globalComponents.sideBar.settings"),
          routerName: [
            "setting",
            "contact",
            "settingInterview",
            "configration",
          ],
          icon: require("@/assets/sidebar/setting.png"),
          disabled: false,
          authority:
            this.$store.state.userData.authority == 1 ||
            this.$store.state.userData.authority == 3,
        },
      ];
    },
  },
  data() {
    return {
      dataSidebar: true,
      widthSide:
        "w-[19%] max-xl:w-[22%] max-lg:w-[28%] max-lg:w-[35%] max-lg:w-full",
      active: "bg-white bg-opacity-10 !border-gray-300 !text-white",
    };
  },
  methods: {
    clearAll() {
      this.verifiedMessage(
        this.$t("globalComponents.sideBar.sureSignOut"),
        this.$t("globalComponents.sideBar.confirmSignOut")
      ).then((res) => {
        if (res.isConfirmed) {
          this.signOut();
        }
      });
    },
  },
  watch: {
    isSidebar(val) {
      this.dataSidebar = val;
    },
    "$i18n.locale"() {
      this.List;
    },
  },
};
</script>
<style>
@media screen and (min-width: 1650px) {
  .maxw {
    width: 19%;
  }
}
</style>
