<template>
    <div class="bg-gray-100 w-full flex rounded-lg p-3">
        <div class="flex items-center gap-2">
            <div
                class="bg-gray-100 text-gray-500 ring-2 w-4 h-4 rounded-sm ring-gray-800/40 flex justify-center items-center">
                <i :class="`${icon} text-sm`"> </i>
            </div>
            <span>

                <slot></slot>
            </span>
        </div>
    </div>
</template>


<script>

export default {
    name: "infoBox",
    props: {
        icon: {
            type: String,
            default: "fa fa-info"
        },
        type: {
            type: String,
            default: "info"
        }
    }
}

</script>