<template>
  <modal
      name="add-school"
      :height="isMobile ? '100%' : 'auto'"
      :width="isMobile ? '100%' : '40%'"
      :scrollable="true"
  >
    <div
        class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.schoolAndEducation.components.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
        @submit.prevent="isEdit ? add() : save()"
        class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
        novalidate
    >
      <div class="space-y-4 pb-3">
        <multiSelectVue
            :maxLength="150"
            :placeholder="
            $t('cv.profile.schoolAndEducation.components.add.scoolName')
          "
            :label="$t('cv.profile.schoolAndEducation.components.add.scoolName')"
            ref="SchoolNameRef"
            v-model="SchoolName"
            :required="true"
            :getOptions="getSchools"
            @input="handleSchoolSelect"
        />

        <multiSelectVue
            :maxLength="100"
            :placeholder="
            $t(
              'cv.profile.schoolAndEducation.components.add.departmentPlacholder'
            )
          "
            :label="
            $t(
              'cv.profile.schoolAndEducation.components.add.departmentPlacholder'
            )
          "
            ref="departmentRef"
            v-model="department"
            :required="true"
            :getOptions="getDepartments"
            @input="handleDepartmentSelect"
        />

        <customInput
            v-model="typeName"
            type="select"
            :selectList="typeList"
            :required="true"
            :is-error="true"
            ref="typeName"
            @input="handleTypeChange"
            :placeholder="
            $t('cv.profile.schoolAndEducation.components.add.typePlacholder')
          "
            :title="$t('cv.profile.schoolAndEducation.components.add.type')"
        />

        <div class="grid grid-cols-2 gap-2">
          <div>
            <label class="textcol font-semibold flex gap-1 items-center"
            >{{
                $t("cv.profile.schoolAndEducation.components.add.startDate")
              }}
              <p class="text-[10px]">
                <i class="fa-sharp fa-solid fa-star-of-life text-[7px]"></i>
              </p>
            </label>
            <datepicker
                v-model="periotStart"
                :format="DatePickerFormat"
                minimum-view="year"
                name="datepicker"
                id="input-id"
                :input-class="'w-full border bordercol rounded py-3 outline-none px-3 text-xs'"
                @input="handleDateChange"
            >
            </datepicker>
          </div>
          <div>
            <label class="textcol font-semibold flex gap-1 items-center">{{
                $t("cv.profile.schoolAndEducation.components.add.finshDate")
              }}</label>
            <datepicker
                v-model="periotEnd"
                :format="DatePickerFormat"
                minimum-view="year"
                name="datepicker"
                id="input-id"
                :input-class="'w-full border bordercol rounded py-3 outline-none px-3 text-xs'"
                @input="handleDateChange"
            >
            </datepicker>
          </div>
        </div>

        <customInput
            v-model="doesItContinue"
            type="select"
            :selectList="[
            {
              value: 1,
              name: $t(
                'cv.profile.schoolAndEducation.components.add.continues'
              ),
            },
            {
              value: 2,
              name: $t(
                'cv.profile.schoolAndEducation.components.add.completed'
              ),
            },
          ]"
            :placeholder="
            $t(
              'cv.profile.schoolAndEducation.components.add.situationPlacholder'
            )
          "
            :title="$t('cv.profile.schoolAndEducation.components.add.situation')"
        />

        <requiredField />
        <div
            class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
                :disabled="!isFormValid"
                :load="load"
                :name="$t('cv.profile.schoolAndEducation.components.add.save')"
                buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import moment from "moment";
import datepicker from "vuejs-datepicker";
import requiredField from "@/components/requiredField.vue";

import { educationLife, utility } from "@/networking/urlmanager.js";

export default {
  name: "addProject",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    datepicker,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,
      isFormValid: false,

      SchoolName: null,
      department: null,
      periotStart: "",
      periotEnd: "",
      typeName: 0,
      doesItContinue: 0,

      DatePickerFormat: "yyyy",
      typeList: [
        {
          value: 0,
          name: this.$t("cv.profile.schoolAndEducation.index.unspecified"),
        },
        {
          value: 1,
          name: this.$t("cv.profile.schoolAndEducation.index.highSchool"),
        },
        {
          value: 2,
          name: this.$t("cv.profile.schoolAndEducation.index.associateDegree"),
        },
        {
          value: 3,
          name: this.$t("cv.profile.schoolAndEducation.index.licence"),
        },
        {
          value: 4,
          name: this.$t("cv.profile.schoolAndEducation.index.degree"),
        },
        {
          value: 5,
          name: this.$t("cv.profile.schoolAndEducation.index.doctorate"),
        },
      ],
    };
  },

  methods: {
    handleSchoolSelect(value) {
      this.SchoolName = value && value.id ? value : null;
      this.validateForm();
    },

    handleDepartmentSelect(value) {
      this.department = value && value.id ? value : null;
      this.validateForm();
    },

    handleTypeChange(value) {
      this.typeName = value;
      this.validateForm();
    },

    handleDateChange() {
      this.validateForm();
    },

    validateForm() {
      this.isFormValid = !!(
          this.SchoolName?.id &&
          this.department?.id &&
          this.typeName &&
          this.periotStart
      );
    },

    resetForm() {
      this.SchoolName = null;
      this.department = null;
      this.periotStart = "";
      this.periotEnd = "";
      this.typeName = 0;
      this.doesItContinue = 0;
      this.isFormValid = false;
      this.load = false;
    },

    show() {
      this.resetForm();
      this.$modal.show("add-school");
    },

    hide() {
      this.$modal.hide("add-school");
      this.resetForm();
    },

    checkErrors() {
      this.$refs.SchoolNameRef.validate();
      this.$refs.departmentRef.validate();
      this.$refs.typeName.validateInput();
      
      const today = moment().format('YYYY');
      if (this.doesItContinue == 2) {
        console.log(this.periotEnd)
        if (!this.periotEnd || !moment(this.periotEnd).isValid()) {
          this.warningMesage(this.$t('cv.profile.schoolAndEducation.components.add.endDateRequired'));
          this.load = false;
          return false;
        }
        
        if (moment(this.periotEnd).isAfter(today)) {
          this.warningMesage(this.$t('cv.profile.schoolAndEducation.components.add.futureDateError'));
          this.doesItContinue = 1;
          this.load = false;
          return false;
        }
      }
      
      this.validateForm();
      return true;
    },

    save() {
      if (!this.isFormValid) return;

      this.load = true;
      if (!this.checkErrors()) return;

      this.$store.commit("addSchool", {
        name: this.SchoolName,
        department: this.department,
        duration: this.periotStart + "-" + this.periotEnd,
        formatDate: this.periotStart + "-" + this.periotEnd,
        status: this.typeName,
        doesItContinue: this.doesItContinue,
      });

      if (!this.isEdit) {
        this.successMessage();
      }

      this.load = false;
      if (this.isEdit) {
        this.$emit("refresh", true);
      }
      this.hide();
    },

    add() {
      if (!this.isFormValid) return;

      this.load = true;
      if (!this.checkErrors()) return;

      const startDate = moment(this.periotStart).format("YYYY.MM.DD");
      const endDate = this.periotEnd
          ? moment(this.periotEnd).format("YYYY.MM.DD")
          : "";

      if (endDate && moment(endDate).isBefore(moment(startDate))) {
        this.warningMesage(
            this.$t("cv.profile.workExperience.components.add.dateError")
        );
        this.load = false;
        return;
      }

      let list = [
        {
          name: this.SchoolName,
          department: this.department,
          duration: this.periotStart + "-" + this.periotEnd,
          formatDate: this.periotStart + "-" + this.periotEnd,
          status: this.typeName,
          doesItContinue: this.doesItContinue,
        },
      ];

      let schoolAndEducation = this.$store.state.extractDetail.schoolAndEducation;

      this.axios
          .post(educationLife.add, {
            cvId: this.$route.params.id,
            list: JSON.stringify(list),
            educationList: schoolAndEducation,
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.save();
          })
          .catch((err) => {
            this.authController(err);
          })
          .finally(() => {
            this.load = false;
          });
    },

    educationCalculator(val) {
      if (val.length > 0) {
        let list = val.sort(function (a, b) {
          return b.status - a.status;
        });
        return this.typeName > list[0].status ? this.typeName : list[0].status;
      } else {
        return this.typeName;
      }
    },

    async getSchools() {
      const response = await this.axios.get(utility.getInstitutionAll);
      return response.data.data;
    },

    async getDepartments() {
      const response = await this.axios.get(utility.getDepartmentAll);
      return response.data.data;
    },
  },

  watch: {
    periotStart: {
      handler(newYear) {
        if (newYear) {
          const dateObject = moment(newYear);
          this.periotStart = dateObject.format("YYYY");
        }
        this.validateForm();
      },
    },
    periotEnd: {
      handler(newYear) {
        if (newYear) {
          const dateObject = moment(newYear);
          this.periotEnd = dateObject.format("YYYY");
        }
        this.validateForm();
      },
    },
    typeName: {
      handler() {
        this.validateForm();
      },
    },
  },
};
</script>

<style>
.vdp-datepicker__calendar {
  width: 100% !important;
  margin-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding: 10px 10px;
}

.vdp-datepicker__calendar .cell {
  height: 35px !important;
  line-height: 35px !important;
  font-size: 13px;
}

.vdp-datepicker__calendar header span {
  font-size: 14px;
}
</style>