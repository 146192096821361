<template>
  <div>
    <multiselect
      :multiple="false"
      v-model="selected"
      :options="List"
      :loading="load"
      label="name"
      track-by="id"
      :placeholder="$t('project.components.positionList.notSelected')"
      :selectLabel="$t('project.components.positionList.choose')"
      :selectedLabel="$t('project.components.positionList.selectChoose')"
      :deselectLabel="$t('project.components.positionList.deselect')"
      openDirection="bottom"
      :taggable="true"
      @tag="addTag"
      :internal-search="false"
      @search-change="asyncFind"
      :disabled="disabled"
    >
    </multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import { utilityPosition } from "@/networking/urlmanager";
import Fuse from "fuse.js";

export default {
  props: ["value", "isEdit", "disabled"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selected: {},
      List: [],
      load: false,
      isActive: true,
      fuse: null,
    };
  },
  methods: {
    addTag(newTag) {
      let tag = {
        name: newTag,
        id: newTag,
        status: 2,
      };
      this.selected = {};
      this.verifiedMessage(
        "'" +
          newTag +
          this.$t("project.components.positionList.warningMessage"),
        this.$t("project.components.positionList.warningMessageReply")
      ).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.axios.post(utilityPosition.add, {
              name: newTag,
            });

            this.selected = tag;
            tag.id = response.data.data;

            this.List.push(tag);
          } catch (err) {
            console.log(err);
            this.authController(err);
          }
        }
      });
    },
    asyncFind(val) {
      if (!this.fuse) return;

      if (!val) {
        this.List = [...this.List];
        return;
      }

      const results = this.fuse.search(val);
      const newFilterList = results.map((result) => result.item);
      this.List = [...newFilterList];
    },

    async getAll() {
      try {
        this.load = true;
        const response = await this.axios.get(utilityPosition.getAll);
        this.List = response.data.data;
        this.load = false;

        this.fuse = new Fuse(this.List, {
          keys: ["name"],
          includeScore: false,
          threshold: 0.4,
        });
      } catch (error) {
        this.load = false;
        console.log(error);
      }
    },
  },
  async created() {
    await this.getAll();
    this.selected = this.value;
  },
  watch: {
    selected(val) {
      this.$emit("change", val);
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>
