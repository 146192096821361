<template>
  <sideModal
    ref="cvFilterPanel"
    modalClass="w-4/12 max-xl:w-10/12 max-sm:w-full"
    :isHeader="true"
    :headerTitle="$t('cv.components.filterPanel.title')"
    :headerRight="true"
  >
    <template v-slot:headerRight>
      <button @click="deleteResumeFilter()" class="flex items-center">
        <span class="text-red-500 border-b border-red-500 text-sm mr-2">{{
          $t("cv.components.filterPanel.reset")
        }}</span>
      </button>
    </template>

    <template v-slot:content>
      <div class="px-3 py-4">
        <form @submit.prevent="handleFilters" class="!h-full">
          <!-- Filtre alanları -->
          <div v-for="(filter, index) in filters" :key="index" class="mb-4">
            <label
              :for="filter.name"
              class="w-full text-sm font-medium mb-1 space-between flex"
            >
              <span class="block w-6/12 max-sm:px-1">
                {{ filter.label }}
              </span>

              <p class="text-right w-6/12" v-if="filter.type == 'range'">
                {{ filter.minValue + "-" + filter.maxValue }}
              </p>
            </label>

            <input
              v-if="filter.type == 'text'"
              :type="filter.type"
              :name="filter.name"
              v-model="filter.value"
              class="w-full border-2 border-344CA0 p-2 rounded text-344CA0"
              :placeholder="filter.placeholder"
            />

            <positionSelect
              v-else-if="filter.type === 'position'"
              v-model="filter.value"
            />

            <multiselect
              v-else-if="
                filter.type === 'multiselect' ||
                filter.type === 'educationLevel'
              "
              v-model="filter.value"
              :options="filter.options"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :placeholder="
                filter.type === 'educationLevel'
                  ? $t('cv.components.filterPanel.select')
                  : $t('cv.components.filterPanel.placeholder')
              "
              track-by="value"
              label="label"
            >
            </multiselect>

            <!-- institution Input -->
            <institutionSelect
              v-else-if="filter.type === 'institution'"
              v-model="filter.value"
            />

            <!-- Skills Input -->
            <skillsSelect
              v-else-if="filter.type === 'skills'"
              v-model="filter.value"
            />

            <!-- department Input -->
            <departmentSelect
              v-else-if="filter.type === 'department'"
              v-model="filter.value"
            />

            <!-- city Input -->
            <citySelect
              v-else-if="filter.type === 'city'"
              v-model="filter.value"
            />

            <div class="w-full" v-else-if="filter.type == 'range'">
              <div>
                <VueSlider
                  :value="[filter.minValue, filter.maxValue]"
                  @change="updateExperienceRange($event, index)"
                  :min="filter.startValue"
                  :max="65"
                  :step="1"
                  width="100%"
                  :dot-size="16"
                  :process-style="{ backgroundColor: '#344CA0' }"
                  :rail-style="{ backgroundColor: '#ccc' }"
                  :dot-style="{
                    backgroundColor: '#344CA0',
                    borderColor: '#344CA0',
                  }"
                />
              </div>
              <span
                class="text-sm flex items-center gap-2.5 justify-end text-gray-700"
              >
                {{ filter.label }}
                {{ $t("cv.components.filterPanel.unspecified") }}
                <input
                  type="checkbox"
                  :checked="filter.isNull == 2"
                  @change="
                    filter.isNull == 2
                      ? (filter.isNull = 1)
                      : (filter.isNull = 2)
                  "
                  class="w-4 h-4 cursor-pointer"
                />
              </span>
            </div>

            <satusSelect
              v-if="filter.type == 'status'"
              v-model="filter.value"
            />
          </div>
          <!-- Filtrele butonu -->
          <buttonItem
            :load="load"
            name="Filtrele"
            buttonClass="w-full font-bold hover:bg-opacity-90 mt-3"
            :disabled="!hasActiveFilters"
          />
        </form>
      </div>
    </template>
  </sideModal>
</template>

<script>
import moment from "moment";
import "moment/locale/tr";
import Multiselect from "vue-multiselect";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import axios from "axios";
import { cv } from "@/networking/urlmanager.js";
import skillsSelect from "./skills-selected.vue";
import positionSelect from "@/components/dev/positionSelected.vue";
import institutionSelect from "@/components/dev/institutionSelect.vue";
import departmentSelect from "@/components/dev/departmentSelect.vue";
import citySelect from "@/components/dev/citySelect.vue";
import satusSelect from "@/components/dev/status.vue";
import buttonItem from "@/components/button.vue";

export default {
  name: "cv-filter-panel",
  components: {
    VueSlider,
    Multiselect,
    departmentSelect,
    institutionSelect,
    skillsSelect,
    positionSelect,
    citySelect,
    satusSelect,
    buttonItem,
  },
  data() {
    return {
      load: false,
      filters: this.initializeFilters(),
      selected: [],
    };
  },
  computed: {
    hasActiveFilters() {
      if (!Array.isArray(this.filters)) return false;

     const activeFilters = this.filters.filter((filter) => {
        if (!filter || typeof filter !== "object") return false;
        // Helper function to check if string value is non-empty
        const isNonEmptyString = (value) =>
          typeof value === "string" && value.trim() !== "";

        // Helper function to check if array value has items
        const isNonEmptyArray = (value) =>
          Array.isArray(value) && value.length > 0;

        switch (filter.type) {
          case "text":
          case "select":
          case "city":
            return isNonEmptyString(filter.value);
          case "department":
          case "position":
          case "multiselect":
          case "skills":
          case "educationLevel":
            return isNonEmptyArray(filter.value);
        
          case "institution":
            return Array.isArray(filter.value)
              ? isNonEmptyArray(filter.value)
              : isNonEmptyString(filter.value);

          case "range":
            return (
              filter.minValue !== filter.startValue ||
              filter.maxValue !== (filter.name === "experience" ? 10 : 28) ||
              filter.isNull === 2
            );

          default:
            return false;
        }
      });
      if (activeFilters.length === 0) return false;
      return true;
    },
  },
  methods: {
    initializeFilters() {
      return [
        {
          name: "jobTitle",
          label: this.$t("cv.components.filterPanel.position"),
          type: "position",
          value: [],
          typeId: "4",
          placeholder: "Title...",
          isActive: false,
        },
        {
          isNull: 1,
          name: "experience",
          label: this.$t("cv.components.filterPanel.experience"),
          type: "range",
          startValue: 0,
          minValue: 0,
          maxValue: 10,
          typeId: "2",
          isActive: false,
        },
        {
          name: "educationLevel",
          label: this.$t("cv.components.filterPanel.educationLevel.title"),
          type: "educationLevel",
          value: [],
          typeId: "1",
          isActive: false,
          options: [
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.primarySchool"
              ),
              value: "0",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.highSchool"
              ),
              value: "1",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.associateDegree"
              ),
              value: "2",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.licence"
              ),
              value: "3",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.highDegree"
              ),
              value: "4",
            },
            {
              label: this.$t(
                "cv.components.filterPanel.educationLevel.doctorate"
              ),
              value: "5",
            },
          ],
        },
        {
          isNull: 1,
          name: "age",
          label: this.$t("cv.components.filterPanel.yearsRange"),
          type: "range",
          startValue: 14,
          minValue: 14,
          maxValue: 28,
          typeId: "2",
          isActive: false,
        },
        {
          name: "skills",
          label: this.$t("cv.components.filterPanel.skillsTitle"),
          type: "skills",
          isActive: false,
          value: [],
          typeId: "4",
          placeholder: "Beceriler (virgülle ayrılmış)",
        },
        {
          name: "institution",
          label: this.$t("cv.components.filterPanel.schoolTitle"),
          type: "institution",
          isActive: false,
          value: "",
          typeId: "4",
          placeholder: "",
        },
        {
          name: "department",
          label: this.$t("cv.components.filterPanel.departmentTitle"),
          type: "department",
          value: "",
          typeId: "4",
          placeholder: "",
          isActive: false,
        },
      ];
    },
    updateExperienceRange(value, index) {
      this.filters[index].minValue = value[0];
      this.filters[index].maxValue = value[1];
    },
    handleFilters(){
      this.$router.push({
        params: {
          currentPage: 0,
        },
      });
      this.applyFilters(0);
    },
    applyFilters(page) {
      const activeFilters = this.filters.filter((filter) => {
        if (!filter || typeof filter !== "object") return false;
        // Helper function to check if string value is non-empty
        const isNonEmptyString = (value) =>
          typeof value === "string" && value.trim() !== "";

        // Helper function to check if array value has items
        const isNonEmptyArray = (value) =>
          Array.isArray(value) && value.length > 0;

        switch (filter.type) {
          case "text":
          case "select":
          case "city":
            return isNonEmptyString(filter.value);
          case "department":
          case "position":
          case "multiselect":
          case "skills":
          case "educationLevel":
            return isNonEmptyArray(filter.value);
        
          case "institution":
            return Array.isArray(filter.value)
              ? isNonEmptyArray(filter.value)
              : isNonEmptyString(filter.value);

          case "range":
            return (
              filter.minValue !== filter.startValue ||
              filter.maxValue !== (filter.name === "experience" ? 10 : 28) ||
              filter.isNull === 2
            );

          default:
            return false;
        }
      });
      if (activeFilters.length === 0) return;

      this.load = true;
      this.$emit("load", true);

      const payload = {
        fList: JSON.stringify(activeFilters),
        page: page || 0,
      };

      axios
        .post(cv.getFilterAll, payload, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.load = false;
          this.$emit("filterAll", res.data.data);
          this.$store.commit("resumeFilter", this.filters);
          this.$refs.cvFilterPanel.isOpen = false
        })
        .catch((error) => {
          this.load = false;
          console.error(error);
        });
    },
    async toggleSidebar() {
      if (!this.$refs.cvFilterPanel.isOpen) {
        this.$refs.cvFilterPanel.show();
      } else {
        this.$refs.cvFilterPanel.close();
      }
    },
    getDate(val) {
      if (val) {
        return moment(val).format("LLL");
      } else {
        return this.$t("cv.components.history.date");
      }
    },
    deleteResumeFilter() {
      this.$store.commit("deleteResumeFilter");
      this.$refs.cvFilterPanel.close();
      this.$emit("deleteFilter", true);
      this.filters = this.initializeFilters();
     
    },
  },
  created() {
    if (Array.isArray(this.$store.state.resumeFilter)) {
      if (this.$store.state.resumeFilter.length > 0) {
        setTimeout(async () => {
          this.filters = await this.$store.state.resumeFilter;
          await this.applyFilters();
        }, 50);
      }
    }
  },
};
</script>
