<template>
  <sideModal
      ref="historyModal"
      modalClass="w-4/12 max-xl:w-10/12 max-sm:w-full"
      :isHeader="true"
      :headerTitle="$t('cv.components.history.title')"
  >
    <template v-slot:external>
      <questionAnsweList
          ref="preInterviewDetail"
          :Detail="selected"
          :projectId="selected.projectId"
      />
    </template>

    <template v-slot:content>
      <div class="bg-white">
        <div class="p-2 max-sm:p-5">
          <div class="my-5 w-full">
            <div class="flex flex-col md:grid grid-cols-12 text-gray-50">
              <div class="flex md:contents">
                <div class="col-start-0 col-end-1 md:mx-auto relative">
                  <div class="h-full w-6 flex items-center justify-center">
                    <div class="h-full w-1 bgcol pointer-events-none"></div>
                  </div>
                  <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bgcol shadow text-center">
                    <i class="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div class="bgcol col-start-2 col-end-12 p-4 rounded-xl my-4 mr-auto shadow-md w-full">
                  <p class="leading-tight text-justify w-full">
                    <span v-if="!load">
                      {{ getDate(List.cvAddDate) }}
                      {{ $t("cv.components.history.addDate") }}</span
                    >
                    <span v-else-if="load && !List.cvAddDate">
                      <i class="fas fa-spinner fa-spin"></i>
                      {{ $t("cv.components.history.loading") }}
                    </span>
                  </p>
                </div>
              </div>

              <div
                  v-for="(item, index) in List.project"
                  :key="index"
                  class="flex md:contents"
                  v-show="item.projectName"
              >
                <div class="col-start-0 col-end-1 md:mx-auto relative">
                  <div class="h-full w-6 flex items-center justify-center">
                    <div class="h-full w-1 bgcol pointer-events-none"></div>
                  </div>
                  <div
                      class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bgcol shadow text-center"
                  >
                    <i class="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div
                    class="col-start-2 col-end-12 rounded-xl my-4 mr-auto w-full"
                >
                  <h3 class="font-semibold mb-1 bgcol px-4 py-2 rounded-xl">
                    {{ formatText(item.projectName) }}
                    {{ $t("cv.components.history.position") }}
                  </h3>
                  <div class="leading-tight text-justify w-full py-2 px-3">
                    <div
                        v-if="!load && item.projectAddDate"
                        class="flex gap-2 items-center py-1"
                    >
                      <span class="w-3 h-3 rounded-full bgcol"></span>
                      <span class="bgcol w-full p-3 rounded-xl text-sm">
                        <p class="font-semibold">
                          {{ getDate(item.projectAddDate) }}
                        </p>
                        {{ $t("cv.components.history.projectAddDate") }}
                      </span>
                    </div>

                    <div
                        v-if="!load && item.interviewMailDate"
                        class="flex gap-2 items-center py-1"
                    >
                      <span class="w-3 h-3 rounded-full bgcol"></span>
                      <span class="bgcol w-full p-3 rounded-xl text-sm">
                        <p class="font-semibold">
                          {{ getDate(item.interviewMailDate) }}
                        </p>
                        {{ $t("cv.components.history.interviewMailDate") }}
                      </span>
                    </div>

                    <div
                        v-if="!load && item.interviewAddDate"
                        class="flex gap-2 items-center py-1 cursor-pointer"
                        @click="
                        () => (
                          selectedItem(item), $refs.preInterviewDetail.show()
                        )
                      "
                    >
                      <span class="w-3 h-3 rounded-full bgcol"></span>
                      <span
                          class="bgcol w-full p-3 rounded-xl text-sm animate-pulse"
                      >
                        <p class="font-semibold">
                          {{ getDate(item.interviewAddDate) | getDate }}
                        </p>
                        {{ $t("cv.components.history.interviewAddDate") }}
                      </span>
                    </div>

                    <div
                        v-if="!load && item.ikComment"
                        class="flex gap-2 items-center py-1"
                    >
                      <span class="w-3 h-3 rounded-full bgcol"></span>
                      <span class="bgcol w-full p-3 rounded-xl text-sm">
                        <p class="font-semibold">
                          {{ $t("cv.components.history.ikComment") }}
                        </p>
                        {{ item.ikComment }}
                      </span>
                    </div>

                    <div
                        v-if="!load && item.inadequateDate"
                        class="flex gap-2 items-center py-1"
                    >
                      <span class="w-3 h-3 rounded-full bgcol"></span>
                      <span class="bgcol w-full p-3 rounded-xl text-sm">
                        <p class="font-semibold">
                          {{ item.inadequateDate | getDate }}
                        </p>
                        {{ $t("cv.components.history.inadequateDate") }} <br/>
                        <b>{{ $t("cv.components.history.note") }}</b>
                        {{ item.inadequateNote }}
                      </span>
                    </div>

                    <div
                        v-if="!load && item.addShortListDate"
                        class="flex gap-2 items-center py-1"
                    >
                      <span class="w-3 h-3 rounded-full bgcol"></span>
                      <span class="bgcol w-full p-3 rounded-xl text-sm">
                        <p class="font-semibold">
                          {{ getDate(item.addShortListDate) }}
                        </p>
                        {{ $t("cv.components.history.addShortListDate") }}<br/>
                      </span>
                    </div>

                    <div
                        v-if="!load && (item.stateId == 6 || item.stateId == 8 )"
                        class="flex gap-2 items-center py-1"
                    >
                      <span class="w-3 h-3 rounded-full" :class="{
                        'bg-red-600' :item.stateId == 8 ,
                        'bg-green-600':item.stateId == 6
                      }"></span>
                      <span :class="{
                        'bg-red-600' :item.stateId == 8 ,
                        'bg-green-600':item.stateId == 6
                      }" class=" w-full p-3 rounded-xl text-sm">
                        <p class="font-semibold">
                          {{
                            item.stateId == 8 ?
                                getDate(item.dismissedDate):
                                getDate(item.workHiredDate)
                          }}
                        </p>
                        {{
                          item.stateId == 8 ?
                              $t("cv.components.history.Inadequate") :
                              $t("cv.components.history.workHiredDate")
                        }}
                      </span>
                    </div>
                    <span v-else-if="load">
                      <i class="fas fa-spinner fa-spin"></i>
                      {{ $t("cv.components.history.loading") }}
                    </span>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </sideModal>
</template>
<script>
import {cv} from "@/networking/urlmanager";
import moment from "moment";
import "moment/locale/tr";
import questionAnsweList from "../../project/projectUser/components/preInterview/detail/index.vue";

export default {
  name: "history-side",
  props: ["Detail"],
  components: {
    questionAnsweList,
  },
  data() {
    return {
      load: false,
      List: [],

      selected: "",
    };
  },
  methods: {
    selectedItem(val) {
      this.selected = {
        ...val,
        cvId: this.List.id,
      };
    },
    async toggleSidebar() {
      this.List = [];
      this.load = true;
      this.$refs.historyModal.show();
      setTimeout(() => {
        this.getAll();
      }, 50);
    },
    getAll() {
      this.axios
          .get(cv.allHistory + "?rowId=" + this.Detail.id)
          .then((res) => {
            this.List = res.data.data;
            this.load = false;
          })
          .catch((err) => {
            this.authController(err);
            this.load = false;
          });
    },
    getDate(val) {
      if (val) {
        return moment(val).format("LLL");
      } else {
        return this.$t("cv.components.history.date");
      }
    },
  },
};
</script>
