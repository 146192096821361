<template>
  <modal
      name="add-seminar-course"
      :height="isMobile ? '100%' : 'auto'"
      :width="isMobile ? '100%' : '30%'"
      :scrollable="true"
  >
    <div
        class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.seminarAndCourse.components.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
        @submit.prevent="isEdit ? add() : save()"
        class="p-5 max-h-screen xl:pb-5 pb-20"
        novalidate
    >
      <div class="space-y-4 pb-3">
        <customInput
            v-model="name"
            type="text"
            min="2"
            max="55"
            :required="true"
            :is-error="true"
            ref="name"
            :placeholder="
            $t('cv.profile.seminarAndCourse.components.add.sertificaPlacholder')
          "
            :title="$t('cv.profile.seminarAndCourse.components.add.sertifica')"
            @input="handleInputChange"
        />

        <customInput
            v-model="periotStart"
            type="date"
            :required="true"
            :is-error="true"
            ref="periotStart"
            :placeholder="
            $t('cv.profile.seminarAndCourse.components.add.startPlacholder')
          "
            :title="$t('cv.profile.seminarAndCourse.components.add.start')"
            @input="handleInputChange"
        />
        <customInput
            v-model="periotEnd"
            type="date"
            :placeholder="
            $t('cv.profile.seminarAndCourse.components.add.finishPlacholder')
          "
            :title="$t('cv.profile.seminarAndCourse.components.add.finish')"
            @input="handleInputChange"
        />

        <requiredField />

        <div
            class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
                :disabled="!isFormValid"
                :load="load"
                :name="$t('cv.profile.seminarAndCourse.components.add.save')"
                buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import moment from "moment";
import { seminarAndCourse } from "@/networking/urlmanager.js";
import requiredField from "@/components/requiredField.vue";

export default {
  name: "computer-skills-add",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,
      isFormValid: false,
      name: "",
      periotStart: "",
      periotEnd: "",
    };
  },
  methods: {
    handleInputChange() {
      this.validateForm();
    },

    validateForm() {
      // İsim minimum 2 karakter ve başlangıç tarihi zorunlu
      this.isFormValid = !!(
          this.name?.trim().length >= 2 &&
          this.periotStart
      );
    },

    resetForm() {
      this.name = "";
      this.periotStart = "";
      this.periotEnd = "";
      this.isFormValid = false;
      this.load = false;
    },

    show() {
      this.resetForm();
      this.$modal.show("add-seminar-course");
    },

    hide() {
      this.$modal.hide("add-seminar-course");
      this.resetForm();
    },

    getDateFormater() {
      let startDate = "";
      let endDate = "";

      if (this.periotStart) {
        startDate = moment(this.periotStart).format("YYYY.MM.DD");
      }
      if (this.periotEnd) {
        endDate = moment(this.periotEnd).format("YYYY.MM.DD");
      }

      return startDate + " - " + endDate;
    },

    validateDates() {
      const startDate = moment(this.periotStart).format("YYYY.MM.DD");
      const endDate = this.periotEnd
          ? moment(this.periotEnd).format("YYYY.MM.DD")
          : "";

      if (endDate && moment(endDate).isBefore(moment(startDate))) {
        this.warningMesage(this.$t('cv.profile.workExperience.components.add.dateError'));
        return false;
      }
      return true;
    },

    save() {
      if (!this.isFormValid) return;

      this.load = true;
      this.checkErrors();

      if (!this.validateDates()) {
        this.load = false;
        return;
      }

      if (this.name?.trim() && this.periotStart) {
        this.$store.commit("addCourseCertificate", {
          name: this.name,
          duration: this.getDateFormater(),
          formatDate: this.getDateFormater(),
        });

        this.successMessage();
        this.hide();
      }

      this.load = false;
    },

    add() {
      if (!this.isFormValid) return;

      this.load = true;
      this.checkErrors();

      if (!this.validateDates()) {
        this.load = false;
        return;
      }

      if (this.name?.trim() && this.periotStart) {
        let list = [
          {
            name: this.name,
            duration: this.getDateFormater(),
            formatDate: this.getDateFormater(),
          },
        ];

        this.axios
            .post(seminarAndCourse.add, {
              cvId: this.$route.params.id,
              list: JSON.stringify(list),
            })
            .then((res) => {
              this.successMessage(res.data.message);
              this.$emit("refresh", true);
              this.hide();
            })
            .catch((err) => {
              this.authController(err);
            })
            .finally(() => {
              this.load = false;
            });
      } else {
        this.load = false;
      }
    },

    checkErrors() {
      this.$refs.name.validateInput();
      this.$refs.periotStart.validateInput();
      this.validateForm();
    },
  },

  watch: {
    name: {
      handler() {
        this.validateForm();
      }
    },
    periotStart: {
      handler() {
        this.validateForm();
      }
    },
    periotEnd: {
      handler() {
        this.validateForm();
      }
    }
  }
};
</script>