<template>
    <modal name="personalInformationModal" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '35%'"
        :scrollable="true">
        <div class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white">
            <h1 class="text-lg">{{
        $t("project.projectUser.components.shortList.components.steps.personalInformation.addPersonalInformation")
    }}
            </h1>
            <button @click="hide()" type="button">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <form @submit.prevent="addNewInterviewType" class="p-5 grid grid-cols-1 gap-4">
            <div v-for="(item, ind) in form" :key="ind">
                <customInput v-model="item.value" :type="item.type"
                    :placeholder="$t('project.projectUser.components.shortList.components.steps.personalInformation.' + item.key + 'Placeholder')"
                    :required="true" :is-error="item.error"
                    :title="$t('project.projectUser.components.shortList.components.steps.personalInformation.' + item.key)" />
            </div>


            <requiredField />

            <buttonItem class="bgcol text-white rounded text-sm" :load="load">
                {{ $t("project.projectUser.components.shortList.components.steps.personalInformation.save") }}
            </buttonItem>
        </form>
    </modal>
</template>
<script>
// import axios from "axios";
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import { settingGeneral } from "@/networking/urlmanager";
import requiredField from "@/components/requiredField.vue";
export default {
    name: "PersonalInformationSettings",

    data() {
        return {
            modalType: 'save',
            load: false,
            informationId: null,
            form: {
                title: {
                    value: '',
                    type: 'text',
                    error: false,
                    key: 'title'
                },
                description: {
                    value: '',
                    type: 'text',
                    error: false,
                    key: 'description'
                }
            },
        };
    },
    components: {
        customInput,
        buttonItem,
        requiredField

    },

    methods: {

        async addNewInterviewType() {
            this.load = true;
            try {

                const response = await this.axios.post(
                    settingGeneral.editPersonalInformationTypes,
                    {
                        id: this.informationId ? this.informationId : undefined,
                        title: this.form.title.value,
                        description: this.form.description.value,
                    }
                );
                this.successMessage();
                this.$emit("success");
                this.hide();
            } catch (error) {
                this.authController(error);
            } finally {
                this.load = false;
            }
        },

        show(type = 'add', item = null) {
            this.modalType = type;
            if (type === 'edit' && item) {
                this.form.title.value = item.title;
                this.form.description.value = item.description;
                this.informationId = item.id;
            } else {
                this.form.title.value = '';
                this.form.description.value = '';
                this.informationId = null;
            }
            this.$modal.show("personalInformationModal");
        },
        hide() {
            this.$modal.hide("personalInformationModal");
            this.form.title.value = '';
            this.form.description.value = '';
            this.informationId = null;
        },

    },


};
</script>
