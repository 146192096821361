<template>
  <div>
    <label class="textcol font-semibold flex gap-1 items-center">
      <p>{{ title ? title : "add Tag" }}</p>
      <p v-if="required" class="text-[10px]">
        <i class="fa-sharp fa-solid fa-star-of-life text-[7px] text-red-600"></i>
      </p>
      <p v-if="maxCount > 0 || minCount > 0" class="text-[10px]">
        ({{ $t("globalComponents.tagInput.minCount") }} = {{ minCount }} -
        {{ $t("globalComponents.tagInput.maxCount") }} = {{ maxCount }})
      </p>
    </label>
    <div class="w-full bg-white rounded-md text-xs font-roboto mb-4 mt-1 bordercol border">
      <div class="flex flex-wrap gap-3 max-h-36 overflow-y-auto" :class="tags.length > 0 && 'p-3'">
        <div v-for="(tag, index) in getList" :key="index" 
          class="border bordercol rounded text-white float-left text-xs bgcol leading-6 font-roboto flex">
          <div class="bg-white flex px-2 py-0.5 text-black rounded-[3px] gap-2">
            <button @click="tagFavorite(tag, index)" class="" type="button" :disabled="disabled">
              <i v-if="tag.isFavorite == 2" class="fa-solid fa-star text-sm"></i>
              <i v-else class="fa-regular fa-star text-sm"></i>
            </button>
            <p>{{ tag.value }}</p>
          </div>
          <button @click="removeTag(tag)" class="bgcol px-2" type="button" :disabled="disabled">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
      <div class="flex">
        <form @submit.prevent="addTag" class="flex w-full" >
          <input type="text" v-model="tagName" :maxlength="maxCount || '30'" :max="maxCount || '30'"
            :placeholder="placeholder ? placeholder : 'Tag Ekle'" :disabled="disabled"
            class="w-full pl-3 outline-none py-3 bg-transparent text-xs"
            :class="tags.length > 0 && ' bordercol border-t'" />
          <button type="submit" class="px-4 bgcol text-white rounded-tr rounded-br" :disabled="disabled">
            <i class="fas fa-plus text-lg"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tags: this.value || [],
      tagName: "",
    };
  },
  props: [
    "title",
    "minCount",
    "maxCount",
    "placeholder",
    "value",
    "isEdit",
    "required",
    "excludedTags",
    "source",
    "disabled",
  ],
  model: {
    event: "change",
    prop: "value",
  },
  methods: {
    addTag() {
      if (
        typeof this.maxCount == "undefined" ||
        this.maxCount > this.tags.length
      ) {
        let val = this.tagName.trim();
        if (val.length > 0) {
          let existingTags = this.tags.map((tag) =>
            tag.value.toLowerCase().replace(/\s/g, "")
          );
          let excludedTagValues = this.excludedTags.map((tag) =>
            tag.value.toLowerCase().replace(/\s/g, "")
          );
          const indexTag = existingTags.indexOf(
            val.toLowerCase().replace(/\s/g, "")
          );
          if (indexTag < 0) {
            if (
              !excludedTagValues.includes(val.toLowerCase().replace(/\s/g, ""))
            ) {
              this.tags.push({
                id:
                  this.tags.length > 0
                    ? this.tags[this.tags.length - 1].id + 1
                    : 1,
                value: val,
                isFavorite: 1,
                stateId: 2,
                favState: 1,
              });
            } else {
              if (this.source === "keywords") {
                this.warningMesage(
                  this.$t("globalComponents.tagInput.sourceKeywords", 2000)
                );
              } else if (this.source === "innovations") {
                this.warningMesage(
                  this.$t("globalComponents.tagInput.sourceInnovations", 2000)
                );
              }
            }
          } else {
            if (this.tags[indexTag].stateId == 3) {
              this.tags[indexTag].stateId = 1;
            } else {
              this.warningMesage(
                this.$t("globalComponents.tagInput.currentLabel")
              );
            }
          }
          this.tagName = "";
        }
      } else {
        this.warningMesage(this.$t("globalComponents.tagInput.MaxLabel"));
      }
    },

 

    removeTag(tag) {
      const findIndex = this.tags.findIndex((item) => item.id === tag.id);
      if (this.isEdit && this.tags[findIndex]?.id) {
        this.tags[findIndex].stateId = 3;
      } else {
        this.tags.splice(findIndex, 1);
      }

    },
    tagFavorite(item, index) {
      item.isFavorite == 2
        ? (this.tags[index].isFavorite = 1)
        : (this.tags[index].isFavorite = 2);
    },
  },
  computed:{
    getList() {
      return this.tags.filter((r) => r.stateId != 3);
    }
  },
  watch: {
    value(newValue) {
      this.tags = newValue;
    },
    tags(val) {
      this.$emit("change", val);
    },
  },
};
</script>
