import i18n from "@/plugins/i18n";

export const projecttour = () => i18n
  .t("globalComponents.tour.projectTour")
  .map((item, index) => ({
    target: `[data-v-step="${index}"]`,
    header: {
      title: item.title,
    },
    content: item.content,
    params: {
      placement: "bottom",
    },
  }));

export const cvtour = () => i18n
  .t("globalComponents.tour.cvtour")
  .map((item, index) => ({
    target: `[data-v-step="${index}"]`,
    header: {
      title: item.title,
    },
    content: item.content,
    params: {
      placement: "bottom",
    },
  }));

export const generaltour = () => i18n
  .t("globalComponents.tour.generaltour")
  .map((item, index) => ({
    target: `[data-v-step="${index}"]`,
    header: {
      title: item.title,
    },
    content: item.content,
    params: {
      placement: "bottom",
    },
  }));

export const userstour = () => i18n
  .t("globalComponents.tour.userstour")
  .map((item, index) => ({
    target: `[data-v-step="${index}"]`,
    header: {
      title: item.title,
    },
    content: item.content,
    params: {
      placement: "bottom",
    },
  }));

export const supporttour = () => i18n
  .t("globalComponents.tour.supporttour")
  .map((item, index) => ({
    target: `[data-v-step="${index}"]`,
    header: {
      title: item.title,
    },
    content: item.content,
    params: {
      placement: "bottom",
    },
  }));

export const settingInterviewtour = () => i18n
  .t("globalComponents.tour.settingInterviewtour")
  .map((item, index) => ({
    target: `[data-v-step="${index}"]`,
    header: {
      title: item.title,
    },
    content: item.content,
    params: {
      placement: "bottom",
    },
  }));

export const projectUserTour = () => i18n
  .t("globalComponents.tour.projectUserTour")
  .map((item, index) => ({
    target: `[data-v-step="${index}"]`,
    header: {
      title: item.title,
    },
    content: item.content,
    params: {
      placement: "bottom",
    },
  }));

export const profileTour = () => i18n
  .t("globalComponents.tour.profileTour")
  .map((item, index) => ({
    target: `[data-v-step="${index}"]`,
    header: {
      title: item.title,
    },
    content: item.content,
    params: {
      placement: "bottom",
    },
  }));
