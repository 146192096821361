var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tour',{attrs:{"name":"myTour","steps":_vm.steps(),"options":{
        useKeyboardNavigation: false,
        labels: {
            buttonSkip: _vm.$t('globalComponents.tour.buttonSkip'),
            buttonPrevious: _vm.$t('globalComponents.tour.buttonPrevious'),
            buttonNext: _vm.$t('globalComponents.tour.buttonNext'),
            buttonStop: _vm.$t('globalComponents.tour.buttonStop')

        }
    },"callbacks":_vm.myCallbacks}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }