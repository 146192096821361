<template>
  <modal
      name="add-project-publications"
      :height="isMobile ? '100%' : 'auto'"
      :width="isMobile ? '100%' : '35%'"
      :scrollable="true"
  >
    <div
        class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.projectsPublications.components.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
        @submit.prevent="isEdit ? add() : save()"
        class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
        novalidate
    >
      <div class="space-y-4 pb-3">
        <customInput
            v-model="name"
            type="text"
            min="2"
            max="55"
            ref="nameInput"
            :required="true"
            :is-error="true"
            :placeholder="$t('cv.profile.projectsPublications.components.add.projectPlacholder')"
            :title="$t('cv.profile.projectsPublications.components.add.project')"
            @input="handleInputChange"
        />

        <customInput
            v-model="url"
            type="url"
            :placeholder="$t('cv.profile.projectsPublications.components.add.projectUrlPLacholder')"
            :title="$t('cv.profile.projectsPublications.components.add.projectUrl')"
            @input="handleInputChange"
        />

        <customInput
            v-model="date"
            type="date"
            :placeholder="$t('cv.profile.projectsPublications.components.add.dataPlacholder')"
            :title="$t('cv.profile.projectsPublications.components.add.data')"
            @input="handleInputChange"
        />

        <customInput
            v-model="description"
            type="textarea"
            max="500"
            ref="textAreaRef"
            :required="true"
            :is-error="true"
            textareaClass="resize-none"
            :placeholder="$t('cv.profile.projectsPublications.components.add.explanationPlacholder')"
            :title="$t('cv.profile.projectsPublications.components.add.explanation')"
            :rows="4"
            @input="handleInputChange"
        />

        <requiredField />
        <div class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0">
          <div class="w-full">
            <buttonItem
                :disabled="!isFormValid"
                :load="load"
                :name="$t('cv.profile.projectsPublications.components.add.save')"
                buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import moment from "moment";
import { projectsPublications } from "@/networking/urlmanager.js";
import requiredField from "@/components/requiredField.vue";

export default {
  name: "computer-skills-add",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,
      isFormValid: false,
      name: "",
      project: "",
      url: "",
      description: "",
      date: "",
    };
  },
  methods: {
    handleInputChange() {
      this.validateForm();
    },

    validateForm() {
      // İsim ve açıklama alanları zorunlu ve minimum 2 karakter olmalı
      this.isFormValid = !!(
          this.name?.trim().length >= 2 &&
          this.description?.trim().length >= 2
      );
    },

    resetForm() {
      this.name = "";
      this.project = "";
      this.description = "";
      this.url = "";
      this.date = "";
      this.isFormValid = false;
      this.load = false;
    },

    show() {
      this.resetForm();
      this.$modal.show("add-project-publications");
    },

    hide() {
      this.$modal.hide("add-project-publications");
      this.resetForm();
    },

    dateFormatter(val) {
      return val ? moment(val).format("YYYY.MM.DD") : "";
    },

    checkErrors() {
      this.$refs.textAreaRef.validateInput();
      this.$refs.nameInput.validateInput();
      this.validateForm();
    },

    save() {
      if (!this.isFormValid) return;

      this.load = true;
      this.checkErrors();

      if (this.name?.trim() && this.description?.trim()) {
        this.$store.commit("addProject", {
          name: this.name,
          description: this.description,
          url: this.url,
          date: this.dateFormatter(this.date),
          formatDate: this.dateFormatter(this.date),
        });

        this.successMessage();
        this.hide();
      }

      this.load = false;
    },

    add() {
      if (!this.isFormValid) return;

      this.load = true;
      this.checkErrors();

      if (this.name?.trim() && this.description?.trim()) {
        let list = [
          {
            name: this.name,
            description: this.description,
            url: this.url,
            date: this.dateFormatter(this.date),
            formatDate: this.dateFormatter(this.date),
          },
        ];

        this.axios
            .post(projectsPublications.add, {
              cvId: this.$route.params.id,
              list: JSON.stringify(list),
            })
            .then((res) => {
              this.successMessage(res.data.message);
              this.$emit("refresh", true);
              this.hide();
            })
            .catch((err) => {
              this.authController(err);
            })
            .finally(() => {
              this.load = false;
            });
      } else {
        this.load = false;
      }
    },
  },

  watch: {
    name: {
      handler() {
        this.validateForm();
      }
    },
    description: {
      handler() {
        this.validateForm();
      }
    }
  }
};
</script>