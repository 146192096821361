<template>
  <modal
    name="personel-information"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{
          $t(
            "project.projectUser.components.shortList.components.personelInformation.index.preliminaryInterviewAppointment"
          )
        }}
      </h1>
      <button @click.stop="hide">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div class="p-10 flex flex-col gap-5 max-sm:p-4">
      <card v-for="(item) in list" :key="item.id" :url="item.fileUrl" :load="load" :title="item.title" />
    
    </div>
  </modal>
</template>

<script>
import { personelInformation } from "@/networking/urlmanager";
import card from "./utility/card.vue";

export default {
  props: ["Detail"],
  name: "select-date-modal",
  components: {
    card,
  },
  data() {
    return {
      load: false,
      goLoad: false,
      list: [],

    };
  },
  methods: {
    show() {
      setTimeout(() => {

        this.getAll();
      }, 50);
      this.$modal.show("personel-information");
    },
    hide() {
      this.$modal.hide("personel-information");
    },
    async getAll() {
      try {
        this.load = true;
        let response = await this.axios.get(personelInformation.getAll);
        this.list = response?.data?.data?.map((item) => {
          return {
            id: item.id,
            title: item.title,
            description: item.description,
            fileUrl: "",
          };
        });
        if(this.list.length > 0){
          await this.getFiles();
        }
        this.load = false;
      } catch (err) {
        this.load = false;
        console.log(err);
        this.authController(err);
      }
    },

    async getFiles() {
      try {
        this.load = true;
        let response = await this.axios.get(personelInformation.getFileList, {
          params:{
            cvId: this.Detail.cvId || this.Detail.id,
            projectId: this.Detail.projectId,
          }
        });
        this.list = this.list.map((item) => {
          let file = response.data.data.find((r) => r.typeId === item.id);
          if (file) {
            item.fileUrl = file.fileUrl;
          }
          return item;
        });
        this.load = false;
      } catch (err) {
        this.load = false;
        console.log(err);
        this.authController(err);
      }
    },
  },
};
</script>
