<template>
  <sideModal
    ref="interviewQuestionModal"
    modalClass="w-8/12 max-xl:w-10/12 max-sm:w-full"
    :isHeader="true"
    :headerTitle="
      $t('project.projectUser.components.interview.index.preInterviewQuestions')
    "
  >
    <template v-slot:external>
      <add ref="add" @refresh="$refs.tableItem.getAll()" :List="List" />
      <edit ref="edit" @refresh="$refs.tableItem.getAll()" :detail="selected" />
      <generateQuestion
        ref="generateQuestion"
        @refresh="$refs.tableItem.getAll()"
        :Detail="List"
      />
    </template>

    <template v-slot:content>
      <div class="bg-white">
        <div class="overflow-y-auto scroltype h-[85vh] p-2 max-sm:p-5">
          <!-- <alert :key="scoreCalculate" :type="scoreCalculate === 100 ? 'success' : 'danger'"
            :message="$t('project.detail.preInterviewDetail.alertTotalScore', { score: scoreCalculate })"
            v-if="List.length" /> -->
            <infoBox v-if="isActive == 1 || load || currentStateId">
              {{  $t(
                    'project.projectUser.components.interview.index.canNotAddQuestion'
                  )
            }}
            </infoBox>

          <div class="my-5 w-full" style="height: calc(100% - 100px)">
            <div class="h-[50px] flex justify-between">
              <asyncButton
                :disabled="isActive == 1 || load || currentStateId"
                type="button"
                @click="$refs.add.show()"
                :text="
                  $t(
                    'project.projectUser.components.interview.index.newQuestion'
                  )
                "
                buttonClass="w-full "
                awesome="fas fa-plus"
                containerBackground="bgcol !shadow md:!w-[165px] justify-center gap-3 !w-full"
                container-class="w-full md:w-auto"
                data-v-step="0"
              />

              <asyncButton
                :disabled="isActive == 1 || load || currentStateId"
                type="button"
                @click="$refs.generateQuestion.show()"
                :text="
                  $t(
                    'project.projectUser.components.interview.index.suggestQuestion'
                  )
                "
                buttonClass="w-full "
                awesome="fas fa-plus "
                containerBackground="bgcol !shadow justify-center gap-3 !w-full"
                container-class="w-full md:w-auto"
                data-v-step="0"
              />
            </div>

            <div>
              <tableInterview
                data-v-step="1"
                ref="tableItem"
                @isActive="(r) => (isActive = r)"
                @editQuestion="editQuestion"
                @List="(r) => (List = r)"
                @evaluationCriteria="
                  (r) => (evaluationCriteria = r ? r : evaluationCriteria)
                "
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </sideModal>
</template>

<script>
import tableInterview from "./components/tableInterview.vue";
import { projectInterview } from "@/networking/urlmanager";
import add from "./components/add.vue";
import edit from "./components/edit.vue";
import generateQuestion from "./components/generateQuestion/index.vue";
import asyncButton from "@/components/button.vue";
import infoBox from "@/components/infoBox.vue";
export default {
  name: "interview-page",
  components: {
    tableInterview,
    add,
    edit,
    generateQuestion,
    asyncButton,
    infoBox
  },
  data() {
    return {
      load: false,
      List: [],
      selected: {},
      isActive: 1,
      evaluationCriteria: "",
      loading: false,
      prevEvaluationCriteria: "",
      isDisabled: true,
    };
  },
  computed: {
    currentStateId() {
      return this.$store.state.project.stateId == 3;
    },
    scoreCalculate() {
      return this.List.reduce((acc, item) => {
        return Number(acc) + Number(item.weightedScore);
      }, 0);
    },
  },
  methods: {
    async toggleSidebar() {
      this.load = true;
      this.List = [];
      this.$refs.interviewQuestionModal.show();
      await setTimeout(async () => {
        await this.$refs.tableItem.getAll();
        this.load = false;
      }, 50);
    },
    editQuestion(val) {
      this.selected = val;
      this.$refs.edit.show();
    },
    addEvaluationCriteria() {
      this.loading = true;
      this.axios
        .post(projectInterview.evaluationCriteria, {
          evaluationCriteria: this.evaluationCriteria,
          projectId: this.$route.query.id,
        })
        .then((res) => {
          this.loading = false;
          this.successMessage(res.data.message);
        })
        .catch((err) => {
          this.loading = false;
          this.authController(err);
        });
    },
    checkCriteria() {
      if (
        this.prevEvaluationCriteria != this.evaluationCriteria &&
        this.evaluationCriteria.trim()
      ) {
        this.isDisabled = false;
      }
    },
    closeErorr() {
      this.authController(
        this.$t("project.detail.preInterviewDetail.alertTotalScore", {
          score: this.scoreCalculate,
        })
      );
    },
  },
  mounted() {
    const intervalId = setInterval(() => {
      if (this.evaluationCriteria) {
        this.prevEvaluationCriteria = this.evaluationCriteria;
        clearInterval(intervalId);
      }
    }, 1000);
  },
  watch: {
    evaluationCriteria(newVal, oldVal) {
      this.checkCriteria();
    },
  },
};
</script>
